<template>
  <v-container>
    <v-row>
      <v-col cols="12" md="10" class="ma-auto">
        <div class="text-h4 mt-4">导数与微分</div>
        <v-row>
          <v-col>
            <v-card class="mt-2">
              <v-container>
                <v-slide-group
                  v-model="chooseItem"
                  mandatory
                  show-arrows
                  active-class="v-btn--active"
                >
                  <v-slide-item
                    v-for="(item, index) in solveItems"
                    :key="index"
                    v-slot="{ active, toggle }"
                  >
                    <v-btn
                      @click="toggle"
                      text
                      class="mx-1 text-none"
                      :color="active ? 'primary' : ''"
                    >
                      {{ index + 1 }}.{{ item.title }}
                    </v-btn>
                  </v-slide-item>
                </v-slide-group>
              </v-container>
              <v-divider />
              <v-container>
                  <v-row>
                      <v-col cols="12">
                          表达式：
                      </v-col>
                <v-col cols="9">
                    <v-text-field v-model="exp"></v-text-field>
                </v-col>
              </v-row>
              </v-container>
              
            </v-card>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <v-btn color="primary" class="float-right" @click="solve"
              >立即计算</v-btn
            >
          </v-col>
        </v-row>
        <v-row v-show="show">
          <v-col>
            <v-card class="my-2">
              <v-row class="pa-4">
                <v-col class="mx-2">
                  <Solve
                    :expression="exp+additional"
                    :items="items"
                    :matherClass="matherClass"
                  />
                </v-col>
              </v-row>
            </v-card>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import Solve from "../../components/Solve";
export default {
  components: {
    Solve,
  },
  data() {
    return {
      detArr: [
        [0, 0, 0, 0],
        [0, 0, 0, 0],
        [0, 0, 0, 0],
        [0, 0, 0, 0],
      ],
      cols: 2,
      rows: 2,
    additional:"&x&x0",
      error: false,
      chooseItem: 0,
      chooseItemTitle: "",
      show: false,
      solveItems: [
        { title: "积分", value: "Function/Differential/Derivative" },
      ],
      exp: "",
      matherClass: "function",
      items: [],
    };
  },
  watch: {
    chooseItem(newValue) {
      console.log(newValue);
      let tempAdditional = {
        积分: () => {
          this.setAdditional("积分");
        },
      };
      this.chooseItemTitle = this.solveItems[newValue].title;
      tempAdditional[this.chooseItemTitle] &&
        tempAdditional[this.chooseItemTitle]();
    },
  },
  methods: {
    solve() {
    //   if(!this.check())return '';
      this.show = true;
    //   console.log(this.getDetString(this.detArr, this.rows, this.cols));

    //   this.exp = this.getDetString(this.detArr, this.rows, this.cols)+this.getAdditional();
      this.items = [this.solveItems[this.chooseItem].value];
    },
    setAdditional() {
      if (this.chooseItemTitle == "积分") {
        this.additional="&x&x0"
        
      }
    },
    getAdditional() {
      let returnString='';
      if (this.chooseItemTitle == "展开") {
        // return selectedRows
        returnString="&i"+this.selectedAdditional;
      }
      return returnString;
    },
//     check(){
//     if (this.error) {
//       return false;
//     }
//     if (this.chooseItemTitle=="展开") {
//       let theSelectedRowisZero=this.detArr[this.selectedAdditional-1].join("")==0
//       let otherRowsisZero=this.getDetString(this.detArr,this.rows-1,this.cols).replace(/;| /g,"")==0
//       return !(theSelectedRowisZero||otherRowsisZero);
//     }
//     return true;
//   },
  },
  
};
</script>

<style scoped>
.border {
  border-left: 2px solid;
  border-right: 2px solid;
}
.childRight {
  position: absolute;
  right: -3.5rem;
  top: -1rem;
}
.childBottom {
  position: absolute;
  bottom: -3.5rem;
  left: -1rem;
  width: 4.5rem;
}
.childRightBottom {
  position: absolute;
  right: -3.5rem;
  bottom: -3.5rem;
}
</style>