<template>
  <v-container>
    <v-row>
      <v-col cols="12" md="10" class="ma-auto">
        <div class="text-h4 mt-4">矩阵与行列式</div>
        <v-row>
          <v-col>
            <v-card class="mt-2">
              <v-container>
                <v-slide-group
                  v-model="chooseItem"
                  mandatory
                  show-arrows
                  active-class="v-btn--active"
                >
                  <v-slide-item
                    v-for="(item, index) in solveItems"
                    :key="index"
                    v-slot="{ active, toggle }"
                  >
                    <v-btn
                      @click="toggle"
                      text
                      class="mx-1 text-none"
                      :color="active ? 'primary' : ''"
                    >
                      {{ index + 1 }}.{{ item.title }}
                    </v-btn>
                  </v-slide-item>
                </v-slide-group>
              </v-container>
              <v-divider />
              <div class="d-flex justify-center pb-15 mt-10">
                <div style="position: relative">
                  <v-row class="border">
                    <v-col v-for="col in cols" :key="col">
                      <v-text-field
                        v-for="row in rows"
                        :key="row"
                        v-model="detArr[row - 1][col - 1]"
                        dense
                        :rules="[rules.number]"
                        style="width: 1.75rem"
                        class="ma-1"
                      />
                    </v-col>
                  </v-row>
                  <div class="childRight">
                    <v-btn icon block @click="addCol"
                      ><v-icon>mdi-table-column-plus-after</v-icon></v-btn
                    >
                    <v-btn icon block @click="deleteCol"
                      ><v-icon>mdi-table-column-remove</v-icon></v-btn
                    >
                  </div>
                  <div class="childBottom">
                    <v-btn icon @click="addRow"
                      ><v-icon>mdi-table-row-plus-after</v-icon></v-btn
                    >
                    <v-btn icon @click="deleteRow"
                      ><v-icon>mdi-table-row-remove</v-icon></v-btn
                    >
                  </div>
                  <div class="childRightBottom">
                    <v-btn icon @click="deleteDet"
                      ><v-icon>mdi-table-refresh</v-icon></v-btn
                    >
                  </div>
                </div>
              </div>
              <v-container>
                <v-row v-if="chooseItemTitle == '展开'">
                  <v-col class="d-flex flex-row align-center">
                    <span>展开行号：</span>

                    <div>
                      <v-select
                        v-model="selectedAdditional"
                        :items="additional"
                        item-text="text"
                        item-value="value"
                        hide-details
                        dense
                        solo
                        style="width: 8rem"
                      ></v-select>
                    </div>
                  </v-col>
                </v-row>
              </v-container>
            </v-card>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <v-btn color="primary" class="float-right" @click="solve"
              >立即计算</v-btn
            >
          </v-col>
        </v-row>
        <v-row v-show="show">
          <v-col>
            <v-card class="my-2">
              <v-row class="pa-4">
                <v-col class="mx-2">
                  <Solve
                    :expression="exp"
                    :items="items"
                    :matherClass="matherClass"
                  />
                </v-col>
              </v-row>
            </v-card>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import Solve from "../../components/Solve";
export default {
  components: {
    Solve,
  },
  data() {
    return {
      detArr: [
        [0, 0, 0, 0],
        [0, 0, 0, 0],
        [0, 0, 0, 0],
        [0, 0, 0, 0],
      ],
      cols: 2,
      rows: 2,
      rules: {
        number: (value) => {
          const pattern = /^(0|[1-9]\d{0,2})$/;
          this.error = !pattern.test(value);
          return !this.error;
        },
      },
      error: false,
      chooseItem: 0,
      chooseItemTitle: "",
      show: false,
      solveItems: [
        { title: "|A|", value: "Matrix/Determinant/|A|" },
        { title: "展开", value: "Matrix/Determinant/Laplace Expansion" },
        { title: "伴随矩阵求逆", value: "Matrix/Inverse/伴随矩阵求逆" },
        { title: "增广矩阵求逆", value: "Matrix/Inverse/A^{-1}" },
      ],
      exp: "",
      additional: "",
      selectedAdditional: 1,
      matherClass: "matrix",
      items: [],
    };
  },
  watch: {
    chooseItem(newValue) {
      console.log(newValue);
      let tempAdditional = {
        展开: () => {
          this.setAdditional("展开");
        },
      };
      this.chooseItemTitle = this.solveItems[newValue].title;
      tempAdditional[this.chooseItemTitle] &&
        tempAdditional[this.chooseItemTitle]();
    },
    cols(newCols) {
      console.log(newCols);
    },
  },
  methods: {
    solve() {
      if(!this.check())return '';
      this.show = true;
      console.log(this.getDetString(this.detArr, this.rows, this.cols));

      this.exp = this.getDetString(this.detArr, this.rows, this.cols)+this.getAdditional();
      this.items = [this.solveItems[this.chooseItem].value];
    },
    getDetString(detArray, rowsLength, colsLength) {
      let rowText = "";

      for (let rowIndex = 0; rowIndex < rowsLength; rowIndex++) {
        for (let colsIndex = 0; colsIndex < colsLength; colsIndex++) {
          rowText += detArray[rowIndex][colsIndex];
          rowText += colsIndex < colsLength - 1 ? " " : "";
        }
        rowText += rowIndex < rowsLength - 1 ? ";" : "";
      }
      return rowText;
    },
    addCol() {
      if (this.cols >= 4) {
        return;
      }
      this.cols += 1;
      this.rows += 1;
      this.setAdditional();
    },
    deleteCol() {
      if (this.cols <= 2) {
        return;
      }
      this.cols -= 1;
      this.rows -= 1;
      this.setAdditional();
    },
    addRow() {
      if (this.rows >= 4) {
        return;
      }
      this.cols += 1;
      this.rows += 1;
      this.setAdditional();
    },
    deleteRow() {
      if (this.rows <= 2) {
        return;
      }
      this.cols -= 1;
      this.rows -= 1;
      this.setAdditional();
    },
    deleteDet() {
      this.detArr = [
        [0, 0, 0, 0],
        [0, 0, 0, 0],
        [0, 0, 0, 0],
        [0, 0, 0, 0],
      ];
      this.setAdditional();
    },
    setAdditional() {
      if (this.chooseItemTitle == "展开") {
        this.additional = [
          {
            text: "第 1 行",
            value: 1,
          },
          {
            text: "第 2 行",
            value: 2,
          },
        ];
        if (this.rows > 2) {
          for (let i = 3; i <= this.rows; i++) {
            this.additional.push({
              text: `第 ${i} 行`,
              value: i,
            });
          }
        }
      }
      this.selectedAdditional = this.rows;
    },
    getAdditional() {
      let returnString='';
      if (this.chooseItemTitle == "展开") {
        // return selectedRows
        returnString="&i"+this.selectedAdditional;
      }
      return returnString;
    },
    check(){
    if (this.error) {
      return false;
    }
    if (this.chooseItemTitle=="展开") {
      let theSelectedRowisZero=this.detArr[this.selectedAdditional-1].join("")==0
      let otherRowsisZero=this.getDetString(this.detArr,this.rows-1,this.cols).replace(/;| /g,"")==0
      return !(theSelectedRowisZero||otherRowsisZero);
    }
    return true;
  },
  },
  
};
</script>

<style scoped>
.border {
  border-left: 2px solid;
  border-right: 2px solid;
}
.childRight {
  position: absolute;
  right: -3.5rem;
  top: -1rem;
}
.childBottom {
  position: absolute;
  bottom: -3.5rem;
  left: -1rem;
  width: 4.5rem;
}
.childRightBottom {
  position: absolute;
  right: -3.5rem;
  bottom: -3.5rem;
}
</style>