<template>
  <div>
    <div id="show"></div>
  </div>
</template>

<script>
export default {
  components: {},
  data() {
    return {};
  },
  mounted() {},
  props: {
    expression: {
      type: String,
    },
    items: {
      type: Array,
    },
    matherClass: {
      type: String,
      default: "",
    },
  },
  watch: {
    items() {
      let solve = window.solve[this.matherClass](this.expression, this.items);
      console.log(solve)
      let showElem = document.getElementById("show");
      window.katex.render(solve[0], showElem, {
        throwOnError: false,
      });
    },
  },
};
</script>

<style lang="scss" scoped>
</style>